import React from "react";
import { useNavigate } from "react-router-dom"
import axios from 'axios';
import { useState,useEffect } from "react";




const SignUp = () => {

    const [name , setName ] = useState("");
    const [email , setEmail ] = useState("");
    const [pass , setPass ] = useState("");

    const [show , setshow ] = useState(false);


    let navigate = useNavigate();

    const ValidateEmail=(inputText)=>
    {
    var mailformat = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if(inputText.match(mailformat))
    {
    
    
    return true;
    }
    else
    {
    
    return true;
    }
    }

    const handleLogin = () => {
        navigate("/login");
    }

    const getReg = async ()=> {
        const data = new FormData();
        data.append('key', "company");
        data.append('username', email);
        data.append('name', name);
        data.append('pass', pass);
         axios({ 
              method: 'post',
              url: '/apis/regis.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                console.log("Regis ",s.data)

            if(s.data[0].code=="already"){
                alert("Account already exists with same details. Try Sign in now.")
                              
           }else if(s.data[0].code=="true")
           {
            alert("Company Account has been created. Please, check your Email to verify the OTP.")

            
            localStorage.setItem("cid",s.data[0].cid)

            navigate("/otp");

           }
         })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

    return (
        <>

       


            <body>
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            <center>
            <img style={{marginTop:"30px"}} src="https://optumpricing.com/ppslogo.png" alt="optumpricing Logo" width="100" height="80" />
            </center>
            <h5 class="card-title text-center mb-5 fw-light fs-5"> Signup to continue  </h5>
            
            <>
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput"  onChange={(vv) => {
                                    setName(vv.target.value)
                                }  } />
                <label for="floatingInput">Company Name</label>
              </div>

              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="floatingInput"  onChange={(vv) => {
                                    setEmail(vv.target.value)
                                }  } />
                <label for="floatingInput">Email</label>
              </div>
              <div class="form-floating mb-3">
                <input type={show?"text":"password"}  class="form-control" id="floatingPassword" onChange={(vv) => {
                                    setPass(vv.target.value)
                                }  }  />
                <label for="floatingPassword">Password</label>
              </div>
              
            

              <div class="form-check mb-3" onClick={()=>{
                setshow(!show)
              }}>


{
    show?<><input class="form-check-input" type="checkbox" checked value="" id="rememberPasswordCheck"/>
    <label class="form-check-label" for="rememberPasswordCheck">
      Show Password
    </label></>:<> <input class="form-check-input" type="checkbox" value="" id="rememberPasswordCheck"/>
                <label class="form-check-label" for="rememberPasswordCheck">
                  Show Password
                </label></>
}

                
              </div>

              <div class="d-grid">
                <button  onClick={()=>{
                  if(name.length<1)
                  {
                    alert("Invalid Name. Please try again.")

                  }else if(pass.length<1)
                  {
                    alert("Invalid Password. Please try again.")

                  }else if(ValidateEmail(email))
                  {
                    getReg()
                  }else{
                    alert("Invalid Email. Please try again.")
                  }

                }} class="btn btn-primary btn-login text-uppercase fw-bold" >Sign
                  up</button>
              </div>
            
              <div onClick = {handleLogin} style={{ marginTop: "15px" }} >
                            <p style = {{margin: " 0" , fontSize: "14px"}}>Already have an account?<span style={{ marginLeft: "10px", color: "blue" , cursor: "pointer"}} >Sign in</span></p>
                        </div>
          
            </>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

            

        </>
    )

}
export default SignUp;