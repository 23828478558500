import React from "react";
import { useNavigate } from "react-router-dom"
import axios from 'axios';
import { useState,useEffect } from "react";




const SignUp = () => {

    const [name , setName ] = useState("");
 
    const [show , setshow ] = useState(false);


    let navigate = useNavigate();
   
    const handleLogin = () => {
        navigate("/login");
    }

    const Resend = async (cid)=> {
        const data = new FormData();
        data.append('cid', cid);
       

     
   
         axios({ 
              method: 'post',
              url: '/apis/resend_mail.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                

                console.log(s.data)
            if(s.data[0].code=="fail"){

                navigate("/login")
                              
           }else if(s.data[0].code=="true")
           {

            alert("Check your email for the OTP!")
            

           }else{
            console.log(s.data)
           }
            
         })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };
    const getReg = async (cid)=> {
        const data = new FormData();
        data.append('cid', cid);
        data.append('otp', name);

     
   
         axios({ 
              method: 'post',
              url: '/apis/varify.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                console.log("otp ",s.data)

                
            if(s.data[0].code=="fail"){

                alert("Verification failed. Please try again")
                              
           }else if(s.data[0].code=="true")
           {
            alert("Company Account has been created. Please, wait for Approval")


            navigate("/login")

            // localStorage.setItem("LOGIN",JSON.stringify(s.data))
            // if(s.data[0].type=="user")
            // {
            //     navigate("/pps")

            // }else{
            //     navigate("/users")
            // }

           }else{
            alert("Verification failed. Please try again ")

           }
            
         })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

    return (
        <>

       


            <body>
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            <center>
            <img style={{marginTop:"30px"}} src="https://optumpricing.com/ppslogo.png" alt="optumpricing Logo" width="100" height="80" />
            </center>
            <h5 class="card-title text-center mb-5 fw-light fs-5">Verification of OTP  </h5>
            
            <>
              <div class="form-floating mb-3">
                <input type="text" class="form-control" value={name} id="floatingInput" placeholder="0000"  onChange={(vv) => {
                    if(vv.target.value.length<5)
                    {
                        setName(vv.target.value)

                    }
                                    
                                }  } />
                <label for="floatingInput">OTP</label>
              </div>


            
              
              <div class="d-grid">
                <button  onClick={()=>{
                    getReg(localStorage.getItem("cid"))
                }} class="btn btn-primary btn-login text-uppercase fw-bold" >Verify</button>
              </div>
              <div class="d-grid" style={{marginTop:"20px",}}>
                <p  >Didn't receive the code? <spen onClick={()=>{
                    Resend(localStorage.getItem("cid"))
                }} style={{color:"blue",cursor:"pointer"}}>Send code again</spen> </p>
              </div>
             
          
            </>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

            

        </>
    )

}
export default SignUp;