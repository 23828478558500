
import "./Admin.scss";
import 'antd/dist/antd.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import { Hypnosis } from "react-cssfx-loading";
import CurrencyInput from 'react-currency-input-field';
import { AiFillHome } from "react-icons/ai";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { FaSave } from "react-icons/fa";
import { CiImport } from "react-icons/ci";
import { AiFillFileExcel } from "react-icons/ai";
import { RiChatHistoryFill ,RiLogoutCircleRFill} from "react-icons/ri";
import { useNavigate } from "react-router-dom"

import {
    PlusCircleFill,
    PlusCircleDotted,
    Trash,
    ArrowClockwise
    ,
    Trash3
  } from "react-bootstrap-icons";

  import "../pages_two/Output.scss"

  import "../../SideBar/SideBar.scss"
  import SideBar from "../../SideBar/SideBar";
  import { Divider, Input, Table } from 'antd';

  import React, { useState,useEffect } from "react";
  




const Admin = () => {

    let navigate = useNavigate();

    useEffect(() => {
        
        const a=JSON.parse(localStorage.getItem("LOGIN"));        

        if(a==null)
        {
          navigate("/")
        }else{
            console.log("a",a)
            if(a[0].type=="company")
            {
                getHistory(a[0].id);
                setLogin(a)
            }else{
                navigate("/pps")
            }
        }
        
		
}, [])
   

    const [login , setLogin ] = useState([]);
    const [select , setSelect ] = useState(0);
    
    const [history , setHistory ] = useState([]);

   



 
    const del = async (id,v)=> {

        
        const data = new FormData();
        data.append('id',id);
        data.append('key',v);
      
    
            axios({ 
              method: 'post',
              url: '/apis/pps_delete.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
            
               if(s.data[0].code=="true")
               {
                
                window.location.reload(false);
               }
    
               
                
                      })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

    const getHistory = async (cid)=> {
        const data = new FormData();
        data.append('cid', cid);
        data.append('key', "all");
    
            axios({ 
              method: 'post',
              url: '/apis/api.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                console.log("history Company",s.data)
            if(s.data.length>0){
                setHistory(s.data)
                setSelect(1)
            }
               
                
                      })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



        
    return (

        <div   >
<div className="container-fluid">
    <div className="row flex-nowrap">
        <div  className="col-auto  col-md-3 col-xl-2 px-sm-2 px-0 bg-dark"  >
            <div  className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-5 d-none d-sm-inline">
                        <img style={{marginTop:"30px"}} src="https://optumpricing.com/ppslogo.png" alt="optumpricing Logo" width="130" height="100" />
                        
                    </span>
                </a>
                <h6>
                <ul  className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li onClick={()=>{
                           navigate("/users")
                                            
                                        }} className="nav-item">
                        <a  className="nav-link align-middle px-0">
                         <AiFillHome color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >Setting</span>
                        </a>
                    </li>
                    <li onClick={()=>{
                           navigate("/history")
                                            
                                        }} className="nav-item">
                        <a  className="nav-link align-middle px-0">
                        <RiChatHistoryFill color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >History</span>
                        </a>
                    </li>



                  
                    <li onClick={()=>{
                        localStorage.removeItem("LOGIN");
                        navigate("/login")
                    }} className="nav-item">
                        <a  className="nav-link align-middle px-0">
                        <RiLogoutCircleRFill color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >Logout</span>
                        </a>
                    </li>
                  
                  
              
                  
                </ul>
                </h6>
                <hr/>
                {/* <div className="dropdown pb-4">
                    <span href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                       
                        <span className="d-none d-sm-inline mx-1">Logout</span>
                    </span>
                  
                </div> */}
            </div>
        </div>

        <div className="col py-3">
        <div classNameName="container-fluid" >
                <div style={{ marginTop:"5px" }}>
                


                   
                    {

                        select === 1 ?
                        
                                <div className="container-fluid" >

<div style={{marginTop:"30px"}} className="row">
<h3>
                                            History
                                        </h3>
                                    <div className="col-11">
                                    <div className="card">
                                        
                                <table  class="table ">
                                
                                
                                <thead className="alert alert-primary" >
                                  <tr>
                                    <th><b>Date & Time</b></th>
                                  
                                    <th><b>User Name</b></th>
                                    <th><b>Comments</b></th>
                                    <th><b>History</b></th>
                                    <th><b>Delete</b></th>
                                    <th><b>Restore</b></th>

                                    
                                    
                                  </tr>
                                </thead>
                                    
                                   
                                <tbody>
                                 
                               {
                                history.map((v,i)=>(
                                   <>
                                   {
                                    v.status=="delete"?<>
                                     <tr style={{color:"red"}}>
                                        <td>
                                            
                                            {v.data}
                                        </td>
                                        <td>
                                            
                                            {v.name}
                                        </td>
                                        <td>
                                            
                                            {JSON.parse(v.array)[0].comments}
                                        </td>



                                        <td>
<BsFillArrowRightCircleFill color="#3755be" style={{margin:"5px"}}
                                                size={20}
                                                onClick={() => {
                                                    localStorage.setItem("ppsid",v.id)
                                                    navigate("/companypps")
                                                 }}
                                              />

                                              

</td>
<td>
<Trash3
                           color="red"
                           size={20}
                           onClick={() => {
                             del(v.id,"delete");
                           }}
                           style={{margin:"2px"}}
                         />

</td>
<td>
<ArrowClockwise
                           color="red"
                           size={20}
                           style={{margin:"2px"}}
                           onClick={() => {
                             del(v.id,"back");
                           }}
                         />

</td>
                                       
                                       
                                    </tr>
                                    </>:<>
                                    <tr>
                                        <td>
                                            
                                            {v.data}
                                        </td>
                                        <td>
                                            
                                            {v.name}
                                        </td>
                                        <td>
                                            
                                            {JSON.parse(v.array)[0].comments}
                                        </td>



                                        <td>
<BsFillArrowRightCircleFill color="#3755be" style={{margin:"5px"}}
                                                size={20}
                                                onClick={() => {
                                                   localStorage.setItem("ppsid",v.id)
                                                   navigate("/companypps")
                                                }}
                                              />

                                              

</td>
<td>
<Trash3
                           color="gray"
                           size={20}
                           onClick={() => {
                             del(v.id,"delete");
                           }}
                           style={{margin:"2px"}}
                         />

</td>
<td>---</td>
                                       
                                       
                                    </tr>

                                  </>
                                   }
                                   </>

                                ))
                               }
                            
                                  
                                
                                
                                </tbody>
                                
                                </table>
                                </div>
                                
                                    </div>
                                
                                </div>




                                             

                                    
                                </div>

                               :<div style={{marginTop:"200px"}}> 
{/* {Loder} */}
<center><Hypnosis color="#ADD8E6" onClick={() => alert("Clicked")} key="key" width="100px" height="100px"  duration="3s" />Loading...</center> </div>
                        
                        
                    }
                    
                </div>



            </div>
        </div>
    </div>
</div>

           

            
             
                    
                    

             
                
           
        </div>
    )

}
export default Admin;