
import "./Admin.scss";
import 'antd/dist/antd.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import { Hypnosis } from "react-cssfx-loading";
import CurrencyInput from 'react-currency-input-field';
import { AiFillHome } from "react-icons/ai";

import { RiChatHistoryFill ,RiLogoutCircleRFill} from "react-icons/ri";
import { useNavigate } from "react-router-dom"



  import "../pages_two/Output.scss"

  import "../../SideBar/SideBar.scss"


  import React, { useState,useEffect } from "react";
  




const Admin = () => {

    let navigate = useNavigate();

    useEffect(() => {
        
        const a=JSON.parse(localStorage.getItem("LOGIN"));        

        if(a==null)
        {
          navigate("/")
        }else{
            console.log("a",a)
            if(a[0].type=="company")
            {
                getLastView(a[0].id);
                setLogin(a)
            }else{
                navigate("/pps")
            }
        }
        
		
}, [])
const ValidateEmail=(inputText)=>
{
var mailformat = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
if(inputText.match(mailformat))
{


return true;
}
else
{

return true;
}
}
   

    const [login , setLogin ] = useState([]);
    const [select , setSelect ] = useState(0);
    
    const [users , setUsers ] = useState("");

    const changeList = (i, changes) => {


        const oldArr = [...users];
        const oldObj = oldArr[i];
        oldArr[i] = { ...oldObj, ...changes };



        setUsers(oldArr);
        
      };

 
    const getSaved = async (id,cid,username,pass,name)=> {

        
        const data = new FormData();
        data.append('id',id);
        data.append('cid', cid);

        data.append('name', name);
        data.append('username',username);
        data.append('pass',pass);
    
            axios({ 
              method: 'post',
              url: '/apis/useredit.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                console.log("saved",s.data)
            
               if(s.data[0].code=="true")
               {
                alert("User account has been created, successfully.")
                window.location.reload(false);
               }else if(s.data[0].code=="already"){

                alert("An account is already registered with this Email. Please, use different Email.                ")
               }
    
               
                
                      })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

    const getLastView = async (cid)=> {
        const data = new FormData();
        data.append('cid', cid);
    
            axios({ 
              method: 'post',
              url: '/apis/getuser.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                console.log("lastview",s.data)
            if(s.data.length>0){
                setUsers(s.data)
                setSelect(1)
            }
               
                
                      })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



        
    return (

        <div   >
<div className="container-fluid">
    <div className="row flex-nowrap">
        <div  className="col-auto  col-md-3 col-xl-2 px-sm-2 px-0 bg-dark"  >
            <div  className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-5 d-none d-sm-inline">
                        <img style={{marginTop:"30px"}} src="https://optumpricing.com/ppslogo.png" alt="optumpricing Logo" width="130" height="100" />
                        
                    </span>
                </a>
                <h6>
                <ul  className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li onClick={()=>{
                           navigate("/users")
                                            
                                        }} className="nav-item">
                        <a  className="nav-link align-middle px-0">
                         <AiFillHome color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >Setting</span>
                        </a>
                    </li>
                    <li onClick={()=>{
                           navigate("/history")
                                            
                                        }} className="nav-item">
                        <a  className="nav-link align-middle px-0">
                        <RiChatHistoryFill color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >History</span>
                        </a>
                    </li>



                  
                    <li onClick={()=>{
                        localStorage.removeItem("LOGIN");
                        navigate("/login")
                    }} className="nav-item">
                        <a  className="nav-link align-middle px-0">
                        <RiLogoutCircleRFill color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >Logout</span>
                        </a>
                    </li>
                  
                  
              
                  
                </ul>
                </h6>
                <hr/>
                {/* <div className="dropdown pb-4">
                    <span href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                       
                        <span className="d-none d-sm-inline mx-1">Logout</span>
                    </span>
                  
                </div> */}
            </div>
        </div>

        <div className="col py-3">
        <div classNameName="container-fluid" >
                <div style={{ marginTop:"5px" }}>
                


                   
                    {

                        select === 1 ?
                        
                                <div className="container-fluid" >
<div style={{marginTop:"40px"}} className="row">
<div className="col-6 ">
                                           <div className="card"  style={{ padding:"20px"}} >
                                           <h5>
                                           User 1 Credentials
                                            </h5>
                                            
                                            <>
  <div className="mb-3">
    <label className="form-label">Company </label>
    <input type="text" class="form-control" value={login.length>0?  login[0].name:""} disabled  aria-describedby="emailHelp" />
    
  </div>


  <div className="mb-3">
    <label className="form-label">Name</label>
    <input type="text" class="form-control"  aria-describedby="emailHelp" value={users.length>0?users[0].name:""} onChange={(v)=>{ changeList(0,{name:v.target.value}) }} />
    
  </div>
  <div class="mb-3">
    <label  className="form-label">Email</label>
    <input type="text" className="form-control" value={users.length>0?users[0].username:""} onChange={(v)=>{ changeList(0,{username:v.target.value}) }} />
  </div>

  <div class="mb-3">
    <label  className="form-label">Password</label>
    <input type="text" className="form-control" value={users.length>0?users[0].pass:""} onChange={(v)=>{ changeList(0,{pass:v.target.value}) }} />
  </div>

  {/* <div className="mb-3">
    <label className="form-label">Type</label>
    <input type="text" class="form-control" value={"User"} disabled  aria-describedby="emailHelp" />
    
  </div> */}

 
  <button type="submit"  onClick={()=>{
   if(!ValidateEmail(users[0].username))
   {
    alert("Invalid Email. Please try again.")

   }else if(users[0].pass.length<1){
    alert("Invalid Password. Please try again.")

   }else if(users[0].name.length<1){
    alert("Invalid Name. Please try again.")
   }else{
    getSaved(users[0].id,login[0].id,users[0].username,users[0].pass,users[0].name)

   }
    
  }}  className="btn btn-primary">Submit</button>
</>
                                            </div>


                                            </div>
  </div>
                                    <div style={{marginTop:"40px"}} className="row">

                                       
                                         
                                        <div className="col-6 ">
                                           <div className="card"  style={{ padding:"20px"}} >
                                           <h5>
                                           User 2 Credentials

                                            </h5>
                                            <>
  <div className="mb-3">
    <label className="form-label">Company</label>
    <input type="text" class="form-control" value={login.length>0?login[0].name:""} disabled  aria-describedby="emailHelp" />
    
  </div> 


  <div className="mb-3">
    <label className="form-label">Name</label>
    <input type="text" class="form-control"  aria-describedby="emailHelp" value={users.length>0?users[1].name:""} onChange={(v)=>{ changeList(1,{name:v.target.value}) }} />
    
  </div>
  <div class="mb-3">
    <label  className="form-label">Email</label>
    <input type="text" className="form-control" value={users.length>0?users[1].username:""} onChange={(v)=>{ changeList(1,{username:v.target.value}) }} />
  </div>

  <div class="mb-3">
    <label  className="form-label">Password</label>
    <input type="text" className="form-control" value={users.length>0?users[1].pass:""} onChange={(v)=>{ changeList(1,{pass:v.target.value}) }} />
  </div>
  {/* <div className="mb-3">
    <label className="form-label">Type</label>
    <input type="text" class="form-control" value={"User"} disabled  aria-describedby="emailHelp" />
    
  </div> */}

 
  <button type="submit" onClick={()=>{
    if(!ValidateEmail(users[1].username))
    {
     alert("Invalid Email. Please try again.")
 
    }else if(users[1].pass.length<1){
     alert("Invalid Password. Please try again.")
 
    }else if(users[1].name.length<1){
     alert("Invalid Name. Please try again.")
    }else{
      getSaved(users[1].id,login[0].id,users[1].username,users[1].pass,users[1].name)
 
    }
   
   
 }} className="btn btn-primary">Submit</button>
</>
                                            </div>


                                            </div>
                                         

                                        </div>
                                  
                                   
                                    

                                   
                                        


                                     
                                             

                                    
                                </div>

                               :<div style={{marginTop:"200px"}}> 
{/* {Loder} */}
<center><Hypnosis color="#ADD8E6" onClick={() => alert("Clicked")} key="key" width="100px" height="100px"  duration="3s" />Loading...</center> </div>
                        
                        
                    }
                    
                </div>



            </div>
        </div>
    </div>
</div>

           

            
             
                    
                    

             
                
           
        </div>
    )

}
export default Admin;