
import './App.css';
import Login from "./containers/Auth/Login/Login"
import SignUp from "./containers/Auth/SignUp/SignUp"

import Admin from "./containers/Pages/Pages_one/Admin"
import Admin_Panel from "./containers/AdminPanel/Admin_Panel"
import AppRoute from './Route/Route';
import {
 
  BrowserRouter,
  Route,
  Routes,

} from "react-router-dom";

function App() {
  return (
    <>
      <AppRoute />
    </>
  );
}

export default App;
