
import 'bootstrap/dist/css/bootstrap.min.css';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import { Hypnosis } from "react-cssfx-loading";
import CurrencyInput from 'react-currency-input-field';
import { AiFillHome } from "react-icons/ai";

import { RiLogoutCircleRFill} from "react-icons/ri";
import { useNavigate } from "react-router-dom"

import {
    
    Trash3
  } from "react-bootstrap-icons";




  import React, { useState,useEffect } from "react";
  




const Admin = () => {

    let navigate = useNavigate();

    useEffect(() => {
        
        const a=JSON.parse(localStorage.getItem("LOGIN_Master"));        

        if(a==null)
        {
          navigate("/")
        }else{
           
              

                getAllCompanies();
                
            
        }
        
		
}, [])
   

    
    
    const [com , setCom ] = useState([]);

    const [activeC , setActiveC ] = useState("Loading");
    const [blockC , setblockC] = useState("Loading");
    const [deleteC , setdeleteC ] = useState("Loading");
    const [total , setTotal ] = useState("Loading");



    const getAllCompanies = async ()=> {
        const data = new FormData();
    
        data.append('key', "company");
    
            axios({ 
              method: 'post',
              url: '/apis/master.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                console.log("all Companies List",s.data)
 

            if(s.data.length>0){

                const sortedList = s.data.sort((a, b) => a.name.localeCompare(b.name));

                const acom=sortedList;
                setCom(acom)
                var a=0;
                var b=0;
                var d=0;
                var t=0;
                for(var i=0;i<s.data.length;i++)
                {
                    t++;
                    if(s.data[i].status=="active")
                    {
                        a++;
                    }else  if(s.data[i].status=="block")
                    {
                        b++;

                    }else  if(s.data[i].status=="delete")
                    {
                        d++
                    }
                    setActiveC(a)
                    setblockC(b)
                    setdeleteC(d)
                    setTotal(t)
                }




               
            }
               
                
                      })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

          const deleteNow = async (cid)=> {
            const data = new FormData();
        
            data.append('key', "delete");
            data.append('cid', cid);
        
                axios({ 
                  method: 'post',
                  url: '/apis/master.php',
                  data: data,
                  headers: {'Content-Type': 'multipart/form-data'},
                })
                  .then(s => {
                    
                    console.log("Delete",s.data)
                    getAllCompanies()
                   
                    
                          })
                  .catch(s => {
                    console.log('here catch =>>', s);
                  });
              };

              const block = async (cid)=> {
                const data = new FormData();
            
                data.append('key', "block");
                data.append('cid', cid);
            
                    axios({ 
                      method: 'post',
                      url: '/apis/master.php',
                      data: data,
                      headers: {'Content-Type': 'multipart/form-data'},
                    })
                      .then(s => {
                        
                        console.log("Delete",s.data)
                        getAllCompanies()
                       
                        
                              })
                      .catch(s => {
                        console.log('here catch =>>', s);
                      });
                  };


                  const active = async (cid,email,name)=> {
                    const data = new FormData();
                
                    data.append('key', "active");
                    data.append('cid', cid);
                    data.append('email', email);
                    data.append('name', name);
                
                        axios({ 
                          method: 'post',
                          url: '/apis/master.php',
                          data: data,
                          headers: {'Content-Type': 'multipart/form-data'},
                        })
                          .then(s => {
                            
                            console.log("Delete",s.data)
                            getAllCompanies()
                           
                            
                                  })
                          .catch(s => {
                            console.log('here catch =>>', s);
                          });
                      };



        
    return (

        <div   >
<div className="container-fluid">
    <div className="row flex-nowrap">
        <div  className="col-auto  col-md-3 col-xl-2 px-sm-2 px-0 bg-dark"  >
            <div  className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-5 d-none d-sm-inline">
                        <img style={{marginTop:"30px"}} src="https://optumpricing.com/ppslogo.png" alt="optumpricing Logo" width="130" height="100" />
                        
                    </span>
                </a>
                <h6>
                <ul  className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li onClick={()=>{
                            // navigate("/pps")
                                            
                                        }} className="nav-item">
                        <a   className="nav-link align-middle px-0">
                         <AiFillHome color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >Companies</span>
                        </a>
                    </li>
                    {/* <li onClick={()=>{
                           navigate("/allhistory")
                                            
                                        }} className="nav-item">
                        <a  className="nav-link align-middle px-0">
                        <RiChatHistoryFill color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >History</span>
                        </a>
                    </li> */}



                  
                    <li onClick={()=>{
                        localStorage.removeItem("LOGIN_Master");
                        navigate("/en/masterlogin")
                    }} className="nav-item">
                        <a  className="nav-link align-middle px-0">
                        <RiLogoutCircleRFill color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >Logout</span>
                        </a>
                    </li>
                  
                  
              
                  
                </ul>
                </h6>
                <hr/>
                {/* <div className="dropdown pb-4">
                    <span href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                       
                        <span className="d-none d-sm-inline mx-1">Logout</span>
                    </span>
                  
                </div> */}
            </div>
        </div>

        <div className="col py-3">
        <div classNameName="container-fluid" >
                <div style={{ marginTop:"5px" }}>
                


                   
                    {
                        
                                <div className="container-fluid" >


<div className='row'>

{/* <div className='col-3'>
    <div class="alert alert-danger" role="alert">
  <h4 class="alert-heading">{total}</h4>
  <p>Total Companies</p>

</div>
        </div> */}





        <div className='col-3'>
    <div class="alert alert-success" role="alert">
  <h4 class="alert-heading">{activeC}</h4>
  <p>Active Companies</p>

</div>
        </div>




        <div className='col-3'>
    <div class="alert alert-secondary" role="alert">
  <h4 class="alert-heading">{blockC}</h4>
  <p>Inactive Companies</p>

</div>
        </div>
        
        {/* <div className='col-3'>
    <div class="alert alert-warning" role="alert">
  <h4 class="alert-heading">{deleteC}</h4>
  <p>Delete Companies</p>

</div>
        </div> */}



    </div>





<div style={{marginTop:"30px"}} className="row">
<h3>
                                            Companies
                                        </h3>
                                    <div className="col-11">
                                    <div className="card">
                                        
                                <table  class="table ">
                                
                                
                                <thead className="alert alert-primary" >
                                  <tr>
                                
                                    <th><b>Company Name</b></th>
                                  <th><b>Email</b></th>

                                  <th><b>Status</b></th>
                                  <th><b>Delete</b></th>
                                 
                                   
                                    
                                  </tr>
                                </thead>
                                    
                                   
                                <tbody>
                                 
                               {
                                com.map((v,i)=>(
                                    
                                   <>
                                   
                                  {

                                    v.status=="delete"?<></>:<>
                                    <tr>
                                         <td>
                                            
                                            {v.name}
                                        </td>
                                     
                                        <td>
                                            
                                            {v.username}
                                        </td>
                                        <td>
                                        {v.status=="active"?<><span onClick={()=>{
                                            block(v.id)
                                        }} style={{color:"black",cursor:"pointer" }}> Active</span></>:<><span style={{color:"gray",cursor:"pointer" }} onClick={()=>{
                                            active(v.id,v.username,v.name)
                                        }}>Inactive </span></> }
                                           
                                            
                                        </td>
                                        <td>
                                        <span style={{color:"red",cursor:"pointer" }}   onClick={()=>{

var answer = window.confirm("Are you sure, you want to delete this Company?");
if (answer) {
    deleteNow(v.id)
}

                                           
                                           }} ><Trash3  />  </span>
                                        </td>
                                    </tr>
                                    </>
                                  }
                                    



                               </>

                                ))
                               }
                            
                                  
                                
                                
                                </tbody>
                                
                                </table>
                                </div>
                                
                                    </div>
                                
                                </div>




                                             

                                    
                                </div>

                                                 
                    }
                    
                </div>



            </div>
        </div>
    </div>
</div>

           

            
             
                    
                    

             
                
           
        </div>
    )

}
export default Admin;