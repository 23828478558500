import React from "react";
import { useNavigate } from "react-router-dom"
import axios from 'axios';
import { useState,useEffect } from "react";




const SignUp = () => {
    //All phase
// 1=> Email
//2 => otp
//3=> changePassword
//4=> to move to Home-page
    const [phase , setphase ] = useState("1");
    const [cid , setCid ] = useState();

  
    const [email , setEmail ] = useState("");


    const [otp , setOtp ] = useState("");

    const [pass , setPass ] = useState("");

    const [show , setshow ] = useState(false);


    let navigate = useNavigate();
    
    const handleLogin = () => {
        navigate("/login");
    }

    const ValidateEmail=(inputText)=>
    {
    var mailformat = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if(inputText.match(mailformat))
    {
    
    
    return true;
    }
    else
    {
    
    return true;
    }
    }


    const phase1 = async ()=> {
        const data = new FormData();
        data.append('key', "p1");
        data.append('username', email);
       
       
         axios({ 
              method: 'post',
              url: '/apis/forget.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                console.log("Regis ",s.data)

           if(s.data[0].code=="true")
           {
            setCid(s.data[0].cid)
            setphase("2")
             

            }else{
                alert("Invalid Email. Please try again.")
            }
         })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

    const phase2 = async ()=> {
        const data = new FormData();
        data.append('key', "p2");
        data.append('cid', cid);
        data.append('otp', otp);
         axios({ 
              method: 'post',
              url: '/apis/forget.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                console.log("forgot ",s.data)

           if(s.data[0].code=="true")
           {
            
            setphase("3")
             

            }else{
                alert("Verification failed. Please try again")
            }
         })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



    const phase3 = async ()=> {
        const data = new FormData();
        data.append('key', "p3");
        data.append('cid', cid);
        data.append('pass', pass);
         axios({ 
              method: 'post',
              url: '/apis/forget.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                console.log("forgot ",s.data)

           if(s.data[0].code=="true")
           {
            
            alert("Password Reset Successfully")
            navigate("/login")
             

            }else{
                alert("Verification failed. Please try again")
            }
         })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };




    const resend = async ()=> {
        const data = new FormData();
        data.append('key', "send");
        data.append('cid', cid);
       
         axios({ 
              method: 'post',
              url: '/apis/forget.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                console.log("forgot ",s.data)

           if(s.data[0].code=="true")
           {
            
            alert("Check your email for the OTP!")
          
             

            }else{
                alert("Verification failed. Please try again")
            }
         })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


 

    return (
        <>

       


            <body>
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            <center>
            <img style={{marginTop:"30px"}} src="https://optumpricing.com/ppslogo.png" alt="optumpricing Logo" width="100" height="80" />
            </center>
            <h5 class="card-title text-center mb-5 fw-light fs-5">Forgot Password</h5>
            
            <>
           
{
    phase=="1"?<>

<div class="form-floating mb-3">
                <input type="email" class="form-control" id="floatingInput" value={email}  onChange={(vv) => {
                                    setEmail(vv.target.value)
                                }  } />
                <label for="floatingInput">Email</label>
              </div>

<div class="d-grid">
                <button  onClick={()=>{
                    if(ValidateEmail(email))
                    {
                        phase1()

                    }else{
                        
                        alert("Invalid Email. Please try again.")
                    }

                }} class="btn btn-primary btn-login text-uppercase fw-bold" >Forgot Password</button>
              </div>
            
              <div onClick = {handleLogin} style={{ marginTop: "15px" }} >
                            <p style = {{margin: " 0" , fontSize: "14px"}}>Already have an account?<span style={{ marginLeft: "10px", color: "blue" , cursor: "pointer"}} >Sign in</span></p>
                        </div>
    </>:<></>
}

{
    phase=="2"?<>

<div class="form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput" value={otp}  onChange={(vv) => {
                                    if(vv.target.value.length<5)
                                    {
                                        setOtp(vv.target.value)
                                    }
                                }  } />
                <label for="floatingInput">OTP</label>
              </div>


              <div class="d-grid" style={{marginTop:"10px",}}>
                <p  >Didn't receive the code? <spen onClick={()=>{
                    resend()
                }} style={{color:"blue",cursor:"pointer"}}>Send code again</spen> </p>
              </div>
              
<div class="d-grid">
                <button  onClick={phase2} class="btn btn-primary btn-login text-uppercase fw-bold" >Verify</button>
              </div>
            
              
    </>:<></>
}

{
    phase=="3"?<>


<div class="form-floating mb-3">
                <input type={show?"text":"password"}  class="form-control" value={pass} id="floatingPassword" onChange={(vv) => {
                                    setPass(vv.target.value)
                                }  }  />
                <label for="floatingPassword">New Password</label>
              </div>
              
            

              <div class="form-check mb-3" onClick={()=>{
                setshow(!show)
              }}>


{
    show?<><input class="form-check-input" type="checkbox" checked value="" id="rememberPasswordCheck"/>
    <label class="form-check-label" for="rememberPasswordCheck">
      Show Password
    </label></>:<> <input class="form-check-input" type="checkbox" value="" id="rememberPasswordCheck"/>
                <label class="form-check-label" for="rememberPasswordCheck">
                  Show Password
                </label></>
}

                
              </div>
              
<div class="d-grid">
                <button  onClick={phase3} class="btn btn-primary btn-login text-uppercase fw-bold" >Reset Password </button>
              </div>
            
              
    </>:<></>
}
             






         
          
            </>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

            

        </>
    )

}
export default SignUp;