import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
} from "react-router-dom";

import Login from "../containers/Auth/Login/Login";
import Masterlogin from "../containers/Auth/Login/Login_master";

import SignUp from "../containers/Auth/SignUp/SignUp";
import Otp from "../containers/Auth/SignUp/otp";
import Forgot from "../containers/Auth/SignUp/forgot";
import Admin_Panel from "../containers/AdminPanel/Admin_Panel";
import History from "../containers/Pages/Pages_one/history";
import History_user from "../containers/Pages/Pages_one/history_user";
import ComapnyPPS from "../containers/Pages/Pages_one/company_pps";
import User from "../containers/Pages/Pages_one/users";
import Csv_app from "../containers/Pages/Pages_one/select_csv";
import Companies from "../master/allusers";



const AppRoute = () => {
    return (
        <BrowserRouter> 
            <Routes>
                {/* Master */}
                <Route path="/en/masterlogin" element={<Masterlogin />} />
                <Route path="/companies" element={<Companies />} />
               

                {/* Both side */}
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<SignUp />}  />


                <Route path="otp" element={<Otp />}  />
                <Route path="forgot" element={<Forgot />}  />

               


             {/* only for company user */}
                <Route path="/pps" element={<Admin_Panel/>} > </Route>
                <Route path="/allhistory" element={<History_user/>} > </Route>

                {/* only for company  */}
                <Route path="/users" element={<User/>} > </Route>
                <Route path="/history" element={<History/>} > </Route>
                <Route path="/companypps" element={<ComapnyPPS/>} > </Route>

                <Route path="/csv" element={<Csv_app/>} > </Route>
                
                <Route path="/" element={<Navigate replace to="/login" />} />

                
            </Routes>





        </BrowserRouter>
    )
}
export default AppRoute 