import React from "react";
import classNames from "classnames";
import "./Login.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Input, Button, Select } from "antd";
import {useNavigate} from "react-router-dom"
import axios from 'axios';
import { useState,useEffect } from "react";

const Login = () => { 
    const [email , setEmail ] = useState("");
    const [pass , setPass ] = useState("");

    const [show , setshow ] = useState(false);

    const ValidateEmail=(inputText)=>
    {
    var mailformat = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if(inputText.match(mailformat))
    {
    
    
    return true;
    }
    else
    {
    
    return true;
    }
    }

    // uyGs1F3c
    useEffect(() => {
        
        const a=JSON.parse(localStorage.getItem("LOGIN"));        

        if(a==null)
        {
          
        }else{
            
            if(a[0].type=="company")
            {
                navigate("/users")
                
            }else{
                navigate("/pps")
            }
        }
        
		
}, [])

    let navigate = useNavigate();
   
    const handleSignUp = () => {
        navigate("/signup")
    }

    const getLogin = async ()=> {

        console.log("click")
        const data = new FormData();
        
        data.append('username', email);
       data.append('pass', pass);
         axios({ 
              method: 'post',
              url: '/apis/login.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                console.log("login ",s.data)

            if(s.data[0].code=="fail"){

                alert("Oops! Email or Password is incorrect.")
                              
           }else if(s.data[0].code=="true")
           {

            localStorage.setItem("LOGIN",JSON.stringify(s.data))
            if(s.data[0].type=="user")
            {
                navigate("/pps")

            }else{
                navigate("/users")
            }

           }else if(s.data[0].code=="otpcompany"){

            localStorage.setItem("cid",s.data[0].cid)

            navigate("/otp");

           }else if(s.data[0].code=="otpuser"){

            alert("your account is not varify ")

           }else if(s.data[0].code=="delete"){

            alert("It seems your Account is inactive, please ask support to activate your Account.")

           }else if(s.data[0].code=="block"){
            alert("It seems your Account is inactive, please ask support to activate your Account.")

           }else{
            alert("Oops! Email or Password is incorrect.")
            

           }
         })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


    return (
        <>



            <body>
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            <center>
            <img style={{marginTop:"30px"}} src="https://optumpricing.com/ppslogo.png" alt="optumpricing Logo" width="100" height="80" />
            </center>
            <h5 class="card-title text-center mb-5 fw-light fs-5">Login to continue</h5>
            
            <>
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" onChange={(vv) => {
                                    setEmail(vv.target.value)
                                }  } />
                <label for="floatingInput">Email</label>
              </div>
              <div class="form-floating mb-3">
                <input type={show?"text":"password"} class="form-control" onChange={(vv) => {
                                    setPass(vv.target.value)
                                }  } id="floatingPassword" placeholder="Password" />
                <label for="floatingPassword">Password</label>
              </div>
              <div onClick={()=>{
                navigate("/forgot");
              }} style={{color:"blue",cursor:"pointer"}} class="text-end">
              Forgot Password?

              </div>
            

              <div class="form-check mb-3" onClick={()=>{
                setshow(!show)
              }}>


{
    show?<><input class="form-check-input" type="checkbox" checked value="" id="rememberPasswordCheck"/>
    <label class="form-check-label" for="rememberPasswordCheck">
      Show Password
    </label></>:<> <input class="form-check-input" type="checkbox" value="" id="rememberPasswordCheck"/>
                <label class="form-check-label" for="rememberPasswordCheck">
                  Show Password
                </label></>
}

                
              </div>
              <div class="d-grid">
                <button onClick = {()=>{
                  if(ValidateEmail(email))
                  {
                    if(pass.length<1)
                    {
                      alert("Invalid Password. Please try again.")
                    }else{
                      getLogin()
                    }
                    
                  }else{
                    alert("Invalid Email. Please try again.")

                  }
                                    
                                }} class="btn btn-primary btn-login text-uppercase fw-bold" >Sign
                  in</button>
              </div>
            
              <div style={{ marginTop: "15px" }} >
                            <p style = {{margin: " 0" , fontSize: "14px"}}>Don't have an account?<span style={{ marginLeft: "10px", color: "blue" , cursor: "pointer"}} onClick = {handleSignUp}>Create Account</span></p>
                        </div>
          
            </>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
        </>
    )

}
export default Login;