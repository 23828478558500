
import "./Admin.scss";
import 'antd/dist/antd.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import axios from 'axios';
import { Hypnosis } from "react-cssfx-loading";

import { FaSave } from "react-icons/fa";
import { useNavigate } from "react-router-dom"
import "../pages_two/Output.scss"
import Papa from "papaparse";
import React, { useState,useEffect } from "react";

const Admin = () => {
    const [login , setLogin ] = useState([]);

    const [bulkpps , setBulkpps ] = useState([]);
    useEffect(() => {

        const a=JSON.parse(localStorage.getItem("LOGIN"));    
        console.log("aaaa",a)    

        if(a==null)
        {
          
        }else{
            
            if(a[0].type=="company")
            {
                navigate("/users")
                
            }else{
                setLogin(a)
            }
        }
        
}, [])

let navigate = useNavigate();


    const [select , setSelect ] = useState(1);
    const [select_view , setSelect_view ] = useState("");
    const [bulk , setbulk ] = useState([]);

    const getSaved = async (uid,cid)=> {

        
        const data = new FormData();
        data.append('key', "add");
        data.append('uid', uid);
        data.append('cid', cid);
        data.append('array',JSON.stringify(bulkpps));
        data.append('array_',"");
    
            axios({ 
              method: 'post',
              url: '/apis/api.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                console.log("saved",s.data)
            
               if(s.data[0].code=="run")
               {
                alert("Data has been saved, Successfully.")
                
                navigate("/pps")
               }
    
               
                
                      })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

   

          useEffect(() => {
        
            const a=JSON.parse(localStorage.getItem("LOGIN"));        
    
            if(a==null)
            {
                navigate("/")
            }else{
                
                if(a[0].type=="company")
                {
                    navigate("/users")
                    
                }else{
                    // navigate("/")
                }
            }
            
            
    }, [])

       
    return (

        <div   >
            {/* style={{backgroundColor:"#3755be"}} */}
<div className="container">
    <div className="row flex-nowrap">


        <div className="col py-3">
        <div classNameName="container" >
                <div style={{ marginTop:"5px" }}>

<center><h3> {select==1?"Import File":"Loading..."}</h3> 
 </center>
  {

                        select == 1 ?
                        
                                <div className="main_Cont" >


<div class="file-upload-wrapper">
<input className="form-control"
type="file"

onChange={(e) => {
  const files = e.target.files;
  console.log(files);
  if (files) {
    
 
    console.log(files[0]);
    
    Papa.parse(files[0], {
      complete: function(results) {


        console.log("Finished:", results.data);
        
        setbulk(results.data)

        const ary_bulk=results.data;
        const oldArr=[ {
            "id":1,
            "total_year_revenue":"",
            "total_cogs":"",
            "total_direct_labour":"",
            "total_sga":"",
            "total_materia_per_year":"",
            "capex_payment_per_year":"",
            "cfo_bookkeeper":"",
            "direct_workers":"",
            "avg_direct_per_hr":"",
            "comments":""
         }];
         let a=ary_bulk.length-1;

        for(let i = 0; i <a; i++)
        {


            if(i==0)
            {
                
    
            }else{
                let newObj = {

                    "id":Math.random(),
                    "product":"",
                    "partnumber":"",
                    "materialCostYear":"",
                    "currentPrise":"",
                    "materialCostUnit":"",
                    "directWork":"",
                    "ProductUnitsYear":"",
                    "directLabour":"",
                    "tof":"",
                    "current_tof":"",
                    "mat_per_unit":"",
                    "minutes_labor_unit":"",
                    "current_avg_direct_labor_per_hr":"",
                    "unit_price_1":"",
                    "unit_price_2":"",
                    "unit_price_3":"",
                    "unit_price_4":"",
                    "unit_price_5":"",
                    "unit_price_6":"",
                    "unit_price_7":"",
                    "net_profitPer_unit_1":"",
                    "net_profitPer_unit_2":"",
                    "net_profitPer_unit_3":"",
                    "net_profitPer_unit_4":"",
                    "net_profitPer_unit_5":"",
                    "net_profitPer_unit_6":"",
                    "net_profitPer_unit_7":"",
                    "grossMargin_1":"",
                    "grossMargin_2":"",
                    "grossMargin_3":"",
                    "grossMargin_4":"",
                    "grossMargin_5":"",
                    "grossMargin_6":"",
                    "grossMargin_7":"",
                    "product_Line_1":"",
                    "product_Line_2":"",
                    "product_Line_3":"",
                    "product_Line_4":"",
                    "product_Line_5":"",
                    "product_Line_6":"",
                    "product_Line_7":"",
                    "array": [] };
                 oldArr.push(newObj);

                 

                //  "product":"",
                //  "partnumber":"",
                //  "materialCostYear":"",
                //  "currentPrise":"",
              
                //  "directWork":"",
                //  "ProductUnitsYear":"",
                //  "directLabour":""
                
                 
        oldArr[i].product =   ary_bulk[i][0] ;
        oldArr[i].partnumber = ary_bulk[i][1];
        oldArr[i].materialCostYear = Number(ary_bulk[i][2]) ;

        oldArr[i].currentPrise =Number(ary_bulk[i][3]) ;

        oldArr[i].directWork = Number(ary_bulk[i][4])  ;
        oldArr[i].ProductUnitsYear = Number(ary_bulk[i][5])  ;

        let sum = 0;
        for (let ii = 0; ii < oldArr.length; ii++) {
            if(ii==0)
            {
        
            }else{
                sum += Number(oldArr[ii].directWork);
            }
        }
        oldArr[0].direct_workers =sum;

        
        //Dirct Number of Labor section A page A End
        
        //Matirial cost  of Labor section A page A start
        
        let sumM = 0;
        for (let si = 0; si < oldArr.length; si++) {
            if(si==0)
            {
        
            }else{
                sumM += Number(oldArr[si].materialCostYear);
            }
        }
        oldArr[0].total_materia_per_year =sumM;
        
        //end
        
        // //avg  cost  of Labor section A page A start
        const am = oldArr[0].direct_workers * oldArr[0].avg_direct_per_hr*2080
        oldArr[0].total_direct_labour = am;
        
    //materialCostUnit

    if(oldArr[i].materialCostYear==""||oldArr[i].ProductUnitsYear=="")
    {
        const am = 0;
             oldArr[i].materialCostUnit = am.toFixed(2); 

    }else{
        const am = Number(oldArr[i].materialCostYear) / Number(oldArr[i].ProductUnitsYear);
             oldArr[i].materialCostUnit = am.toFixed(2); 
    }

             
    if(isFinite(60*  (Number(oldArr[i].directWork)*2080 ) / Number(oldArr[i].ProductUnitsYear))==true)
    {
        oldArr[i].directLabour =  ( 60*  (Number(oldArr[i].directWork)*2080 ) / Number(oldArr[i].ProductUnitsYear)).toFixed(2)

    }else{
        oldArr[i].directLabour =  0
    }
       


// tof and current tof both only section 1

    
    const a1=  Number( oldArr[0].total_year_revenue)
    const a2= Number(oldArr[0].total_cogs)
    const a3= Number(oldArr[0].total_direct_labour)
    const a4= Number(oldArr[0].total_sga)
    const a5= Number(oldArr[0].total_materia_per_year)
    const a6= Number(oldArr[0].capex_payment_per_year)
    const a7= Number(oldArr[0].cfo_bookkeeper)
    const a8= Number(oldArr[0].direct_workers)
    const a9= Number(oldArr[0].avg_direct_per_hr)

// tof start
const tof=  ((((a2+a4)+a6-a5)-(2080*(a9*a8))) /(2080*a8))+a9
if(isFinite(tof))
{
oldArr[0].tof =  tof
}else{
oldArr[0].tof =  0
}
// tof end
oldArr[i].current_avg_direct_labor_per_hr = a9

// Current Tof  start
const currentTof= tof-a9+(Number( oldArr[i].current_avg_direct_labor_per_hr));
if(isFinite(currentTof))
{
oldArr[i].current_tof =  currentTof;
}else{
oldArr[i].current_tof = 0;
}
//current tof end



// tof and current tof both only section 1


//table section page 2

console.log("here-->",oldArr[i].materialCostUnit)
oldArr[i].unit_price_1 = oldArr[i].currentPrise;
oldArr[i].unit_price_2 =Number( oldArr[i].materialCostUnit) + Number(oldArr[i].directLabour) * Number(oldArr[i].current_tof) / 60 ;
oldArr[i].unit_price_3 =  (1+0.05)* Number(oldArr[i].unit_price_2);
oldArr[i].unit_price_4 = (1+0.075)* oldArr[i].unit_price_2;
oldArr[i].unit_price_5 = (1+0.1)* oldArr[i].unit_price_2;
oldArr[i].unit_price_6 = (1+0.15)* oldArr[i].unit_price_2;
oldArr[i].unit_price_7 = (1+0.2)* oldArr[i].unit_price_2;

oldArr[i].net_profitPer_unit_1 = Number(oldArr[i].unit_price_1) - Number(oldArr[i].unit_price_2);
oldArr[i].net_profitPer_unit_2 = oldArr[i].unit_price_2 - oldArr[i].unit_price_2;
oldArr[i].net_profitPer_unit_3 = oldArr[i].unit_price_3 - oldArr[i].unit_price_2;
oldArr[i].net_profitPer_unit_4 = oldArr[i].unit_price_4 - oldArr[i].unit_price_2;
oldArr[i].net_profitPer_unit_5 = oldArr[i].unit_price_5 - oldArr[i].unit_price_2;
oldArr[i].net_profitPer_unit_6 = oldArr[i].unit_price_6 - oldArr[i].unit_price_2;
oldArr[i].net_profitPer_unit_7 = oldArr[i].unit_price_7 - oldArr[i].unit_price_2;

//const a9= Number(ListInput[0].avg_direct_per_hr)

oldArr[i].grossMargin_1 = Number(oldArr[i].unit_price_1) - Number(oldArr[i].materialCostUnit)- Number(oldArr[i].directLabour) * Number(a9)/60
oldArr[i].grossMargin_2 = Number(oldArr[i].unit_price_2) - Number(oldArr[i].materialCostUnit)- Number(oldArr[i].directLabour) * Number(a9)/60
oldArr[i].grossMargin_3 = Number(oldArr[i].unit_price_3) - Number(oldArr[i].materialCostUnit)- Number(oldArr[i].directLabour) * Number(a9)/60
oldArr[i].grossMargin_4 = Number(oldArr[i].unit_price_4) - Number(oldArr[i].materialCostUnit)- Number(oldArr[i].directLabour) * Number(a9)/60
oldArr[i].grossMargin_5 = Number(oldArr[i].unit_price_5) - Number(oldArr[i].materialCostUnit)- Number(oldArr[i].directLabour) * Number(a9)/60
oldArr[i].grossMargin_6 = Number(oldArr[i].unit_price_6) - Number(oldArr[i].materialCostUnit)- Number(oldArr[i].directLabour) * Number(a9)/60
oldArr[i].grossMargin_7 = Number(oldArr[i].unit_price_7) - Number(oldArr[i].materialCostUnit)- Number(oldArr[i].directLabour) * Number(a9)/60



oldArr[i].product_Line_1 =Number(oldArr[i].net_profitPer_unit_1 )* Number(oldArr[i].ProductUnitsYear )
oldArr[i].product_Line_2 =Number(oldArr[i].net_profitPer_unit_2 )* Number(oldArr[i].ProductUnitsYear )
oldArr[i].product_Line_3 =Number(oldArr[i].net_profitPer_unit_3 )* Number(oldArr[i].ProductUnitsYear )
oldArr[i].product_Line_4 =Number(oldArr[i].net_profitPer_unit_4 )* Number(oldArr[i].ProductUnitsYear )
oldArr[i].product_Line_5 =Number(oldArr[i].net_profitPer_unit_5 )* Number(oldArr[i].ProductUnitsYear )
oldArr[i].product_Line_6 =Number(oldArr[i].net_profitPer_unit_6 )* Number(oldArr[i].ProductUnitsYear )
oldArr[i].product_Line_7 =Number(oldArr[i].net_profitPer_unit_7 )* Number(oldArr[i].ProductUnitsYear )

 //main



if(i==0)
{

}
else{
const array= [
    {
        name: "",
        profitA: 0,
        profitB: 0,
        c: 0,

    },
    {
        name: "",
        profitA: 0,
        profitB: 0,
        c: 0,
    },
    {
        name: "",
        profitA: 0,
        profitB: 0,
        c: 0,
        
    },
    {
        name: "",
        profitA: 0,
        profitB: 0,
        c: 0,
        
    },
    {
        name: "",
        profitA: 0,
        profitB: 0,
        c: 0,
        
    },
    {
        name: "",
        profitA: 0,
        profitB: 0,
        c: 0,
      
    },
    {
        name: "",
        profitA: 0,
        profitB: 0,
        c:0
        
    }
]

array[0].name= Number(oldArr[i].unit_price_1).toFixed(2)
array[1].name=  Number(oldArr[i].unit_price_2).toFixed(2)
array[2].name= Number(oldArr[i].unit_price_3).toFixed(2)
array[3].name= Number(oldArr[i].unit_price_4).toFixed(2)
array[4].name= Number(oldArr[i].unit_price_5).toFixed(2)
array[5].name= Number(oldArr[i].unit_price_6).toFixed(2)
array[6].name= Number(oldArr[i].unit_price_7).toFixed(2)



array[0].profitA=Number(oldArr[i].net_profitPer_unit_1).toFixed(2)
array[1].profitA=Number(oldArr[i].net_profitPer_unit_2).toFixed(2)
array[2].profitA=Number(oldArr[i].net_profitPer_unit_3).toFixed(2)
array[3].profitA=Number(oldArr[i].net_profitPer_unit_4).toFixed(2)
array[4].profitA=Number(oldArr[i].net_profitPer_unit_5).toFixed(2)
array[5].profitA=Number(oldArr[i].net_profitPer_unit_6).toFixed(2)
array[6].profitA=Number(oldArr[i].net_profitPer_unit_7).toFixed(2)


array[0].profitB=Number(oldArr[i].grossMargin_1).toFixed(2)
array[1].profitB=Number(oldArr[i].grossMargin_2).toFixed(2)
array[2].profitB=Number(oldArr[i].grossMargin_3).toFixed(2)
array[3].profitB=Number(oldArr[i].grossMargin_4).toFixed(2)
array[4].profitB=Number(oldArr[i].grossMargin_5).toFixed(2)
array[5].profitB=Number(oldArr[i].grossMargin_6).toFixed(2)
array[6].profitB=Number(oldArr[i].grossMargin_7).toFixed(2)

oldArr[i].array =array;
}     

        }


        setBulkpps(oldArr)
console.log("abc",oldArr)








      }}}
    )
  
  }


}}
/>
</div>
                                  
                                    <div className="section_one">
                                    <div className="row" style={{marginTop:"10px"}}  >
                                        


{
    bulkpps.length>1?<><div className="col-auto text-right">
    <button  type="button" style={{marginTop:"5px",width:"220px",height:"50px"}} onClick={()=>{
    
    getSaved(login[0].id,login[0].cid)
    
    }} class="btn btn-success">Save History <FaSave color="white" size={16} style={{marginBottom:"7px"}} /> </button>
    </div></>:<></>
}





</div>
 </div>

                                    <h4 style={{marginTop:"20px"}}>Product Line Data</h4>

                                   
                                    <table className="table" >
                                        <tr style={{backgroundColor:"lightBlue"}} >
                                            <td><center><b>Sr.</b></center></td>
                                            <td><center><b>Products</b></center></td>
                                            <td><center><b>Part Number</b></center></td>

                                            <td><center><b>Product Mat'l /Year</b></center></td>
                                            <td><center><b>Current Price/Bid</b></center></td>

                                            <td><center><b>Material Cost/Unit</b></center></td>
                                            <td><center><b>Direct Workers</b></center></td>

                                            <td><center><b>Production Units/Yr</b></center></td>
                                            <td><center><b>Direct Labor Unit/Min</b></center></td>
                                          
                                        </tr>
                           
                                        
           
           {/* 
           1 "product":"",
         2  "partnumber":"",
         3  "materialCostYear":"",
           "currentPrise":"",
           "materialCostUnit":"",
           "directWork":"",
           "ProductUnitsYear":"",
           "directLabour":"",
           "current_tof":"",
           "mat_per_unit":"",
           "minutes_labor_unit":"",
           "current_avg_direct_labor_per_hr":"",
           */}





                                    {
                                        bulkpps.map((v,i)=>(
                                            <>
                                            {
                                               i==0?<></>:<>
                                               <tr>
                                               <td><center><b>{i}</b></center></td>
                                            <td><center><b>{v.product}</b></center></td>
                                            <td><center><b>{v.partnumber}</b></center></td>

                                            <td><center><b>{v.materialCostYear}</b></center></td>

                                            <td><center><b>{v.currentPrise}</b></center></td>
                                            <td><center><b>{v.materialCostUnit}</b></center></td>
                                            <td><center><b>{v.directWork}</b></center></td>

                                            <td><center><b>{v.ProductUnitsYear}</b></center></td>
                                            <td><center><b>{v.directLabour}</b></center></td>
                                            
                                            </tr>
                                            </>
                                            }
                                            
                                            </>
                                        )
                                        )
                                    }


                                    </table>
                                    
                                        
                                             

                                    
                                </div>

                                :<div style={{marginTop:"200px"}}> 

<center><Hypnosis color="#ADD8E6" onClick={() => alert("Clicked")} key="key" width="100px" height="100px"  duration="3s" />Loading...</center> </div>
                        
                        
                    }
                    
                </div>



            </div>
        </div>
    </div>
</div>

           

            
             
                    
                    

             
                
           
        </div>
    )

}
export default Admin;