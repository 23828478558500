import React from "react";
import "./Login.scss"
import { Input, Button, Select } from "antd";
import {useNavigate} from "react-router-dom"
import axios from 'axios';
import { useState,useEffect } from "react";

const Login = () => { 
    let navigate = useNavigate();
    const handleClick  = () => {


        getLogin()
    }
        const [email , setEmail ] = useState("");
    const [pass , setPass ] = useState("");
    const [show , setshow ] = useState(false);


    const getLogin = async ()=> {
        const data = new FormData();
        
        data.append('key', "login");
        data.append('username', email);
       data.append('pass', pass);
         axios({ 
              method: 'post',
              url: '/apis/master.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {

            if(s.data[0].code=="fail"){
                alert("your account not found please try again")
                              
           }else if(s.data[0].code=="true")
           {

            localStorage.setItem("LOGIN_Master",JSON.stringify(s.data))
            
                navigate("/companies")
           }
         })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

    useEffect(() => {
        
        const a=JSON.parse(localStorage.getItem("LOGIN_Master"));        

        if(a==null)
        {
          
        }else{
            
          
                navigate("/companies")
        
        }
        
		
}, [])




    return (
        <>

            


            <body>
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            <center>
            <img style={{marginTop:"30px"}} src="https://optumpricing.com/ppslogo.png" alt="optumpricing Logo" width="100" height="80" />
            </center>
            <h5 class="card-title text-center mb-5 fw-light fs-5"><b>Master Login</b></h5>
            
            <>
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" onChange={(vv) => {
                                    setEmail(vv.target.value)
                                }  } />
                <label for="floatingInput">Email</label>
              </div>
              <div class="form-floating mb-3">
                <input type={show?"text":"password"}  class="form-control" id="floatingPassword" placeholder="Password" onChange={(vv) => {
                                    setPass(vv.target.value)
                                }  } />
                <label for="floatingPassword">Password</label>
              </div>
              
            

              <div class="form-check mb-3" onClick={()=>{
                setshow(!show)
              }}>


{
    show?<><input class="form-check-input" type="checkbox" checked value="" id="rememberPasswordCheck"/>
    <label class="form-check-label" for="rememberPasswordCheck">
      Show Password
    </label></>:<> <input class="form-check-input" type="checkbox" value="" id="rememberPasswordCheck"/>
                <label class="form-check-label" for="rememberPasswordCheck">
                  Show Password
                </label></>
}

                
              </div>

              <div class="d-grid">
                <button onClick = {handleClick} class="btn btn-primary btn-login text-uppercase fw-bold" >Sign
                  in</button>
              </div>
            
         
          
            </>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
        </>
    )

}
export default Login;