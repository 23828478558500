
import "./Admin.scss";
import 'antd/dist/antd.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import { Hypnosis } from "react-cssfx-loading";
import CurrencyInput from 'react-currency-input-field';
import { AiFillHome } from "react-icons/ai";
import { VscOutput } from "react-icons/vsc";
import { FaSave } from "react-icons/fa";
import { CiImport } from "react-icons/ci";
import { AiFillFileExcel ,AiFillPrinter} from "react-icons/ai";
import { FcPrint} from "react-icons/fc";
import { RiChatHistoryFill ,RiLogoutCircleRFill} from "react-icons/ri";
import { useNavigate } from "react-router-dom"



  import "../pages_two/Output.scss"

  import "../../SideBar/SideBar.scss"
  import SideBar from "../../SideBar/SideBar";
  import { Divider, Input, Table } from 'antd';

  import React, { useState,useEffect } from "react";
  
  import {
      LineChart,
      Line,
      XAxis,
      YAxis,
      CartesianGrid,
      ResponsiveContainer,
      LabelList
  } from "recharts";



const Admin = () => {
    const [login , setLogin ] = useState([]);
    useEffect(() => {

        const a=JSON.parse(localStorage.getItem("LOGIN"));    
        console.log("aaaa",a)    

        if(a==null)
        {
          
        }else{
            
            if(a[0].type=="company")
            {
                setLogin(a)
                
            }else{
               
                navigate("/users")
            }
        }



        const pid=JSON.parse(localStorage.getItem("ppsid"));    
         

        if(pid==null)
        {
           navigate("/users")
          
        }else{
            
         getHistoryPPsid(pid,a[0].id)
        }

        

        
      
		

}, [])

let navigate = useNavigate();
    const [active , setActive ] = useState(false);

    const [select , setSelect ] = useState(0);
    const [select_view , setSelect_view ] = useState("");
    const [historyopen , setHistoryopen ] = useState(false);
    const [selectProductIndex , setSelectProductIndex ] = useState(1);


    
 


    

   const [test,setTest]=useState("");
   

      const [ProductListInput,setProductListInput] = useState([
        {
            "id":1,
            "total_year_revenue":"",
            "total_cogs":"",
            "total_direct_labour":"",
            "total_sga":"",
            "total_materia_per_year":"",
            "capex_payment_per_year":"",
            "cfo_bookkeeper":"",
            "direct_workers":"",
            "avg_direct_per_hr":"",
            "tof":"",
            "comments":""
         },{
           "id":2,
           "product":"",
           "partnumber":"",
           "materialCostYear":"",
           "currentPrise":"",
           "materialCostUnit":"",
           "directWork":"",
           "ProductUnitsYear":"",
           "directLabour":"",
           "current_tof":"",
           "mat_per_unit":"",
           "minutes_labor_unit":"",
           "current_avg_direct_labor_per_hr":"",
           "unit_price_1":"",
           "unit_price_2":"",
           "unit_price_3":"",
           "unit_price_4":"",
           "unit_price_5":"",
           "unit_price_6":"",
           "unit_price_7":"",
           "net_profitPer_unit_1":"",
           "net_profitPer_unit_2":"",
           "net_profitPer_unit_3":"",
           "net_profitPer_unit_4":"",
           "net_profitPer_unit_5":"",
           "net_profitPer_unit_6":"",
           "net_profitPer_unit_7":"",
           "grossMargin_1":"",
           "grossMargin_2":"",
           "grossMargin_3":"",
           "grossMargin_4":"",
           "grossMargin_5":"",
           "grossMargin_6":"",
           "grossMargin_7":"",
           "product_Line_1":"",
           "product_Line_2":"",
           "product_Line_3":"",
           "product_Line_4":"",
           "product_Line_5":"",
           "product_Line_6":"",
           "product_Line_7":"",
           "array": [],
          
           
          
        }
     ])

     const getHistoryPPsid= async (id,cid)=> {
        setHistoryopen (true)
        const data = new FormData();
        data.append('id',id);
        data.append('cid',cid);

    
            axios({ 
              method: 'post',
              url: '/apis/pps_byid.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
            console.log("get history by id",s.data)
          
            if(s.data.length<1){
                setSelect(1)
            }
               else if(s.data[0].code=="pass")
               {
                setSelect(1)

                console.log("Array",s.data[0].array)
               
                setTest(s.data[0].array)
               setProductListInput(JSON.parse(s.data[0].array))

               //localStorage.getItem("ppsid")
              
               

               }
    
               
                
                      })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

      const formatter = new Intl.NumberFormat('en-US', { style: 'currency',currency: 'USD', });





// hint

   


    


   

    



        
    


   

    const CustomizedLabel = (props) => {
        const { x, y, stroke, value } = props;

        return (
            <text x={x} y={y} dy={-10} fill={stroke} fontSize={15} textAnchor="middle">
                {"$" + value}
            </text>
        );

    }
    const CustomizedXAxisTick =(props) =>{
        const {x , y ,payload} = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={15}
                    y={0}
                    dy={16}
                    textAnchor="end"

                    style={{
                        fontWeight: "500",
                        color: "#000000",
                        fontSize: "14px"

                    }}

                >
                    ${payload.value}
                </text>
            </g>

        )

    }
    const CustomizedYAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={-8}
                    dy={16}
                    textAnchor="end"

                    style={{
                        fontWeight: "500",
                        color: "#000000",
                        fontSize: "14px"

                    }}

                >
                    {"$" + parseFloat(payload.value).toFixed(2)}
                </text>
            </g>
        )


    }

 
  


       
    return (

        <div   >
            {/* style={{backgroundColor:"#3755be"}} */}
<div className="container-fluid">
    <div className="row flex-nowrap">
        <div  className="col-auto  col-md-3 col-xl-2 px-sm-2 px-0 bg-dark"   >
            <div  className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-5 d-none d-sm-inline">
                        <img style={{marginTop:"30px"}} src="https://optumpricing.com/ppslogo.png" alt="optumpricing Logo" width="130" height="100" />
                        
                    </span>
                </a>
                <h6>
                <ul  className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li onClick={()=>{
                            if(select_view=="save")
                            {
                                window.location.reload();
                            }else{
                                setSelect(1)
                            }
                                            
                                        }} className="nav-item">
                        <a   className="nav-link align-middle px-0">
                         <AiFillHome color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >P&L Data</span>
                        </a>
                    </li>
                    <li onClick={()=>{
                           navigate("/history")
                                            
                                        }} className="nav-item">
                        <a  className="nav-link align-middle px-0">
                        <RiChatHistoryFill color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >History</span>
                        </a>
                    </li>



                  
                    <li onClick={()=>{
                        localStorage.removeItem("LOGIN");
                        navigate("/login")
                    }} className="nav-item">
                        <a  className="nav-link align-middle px-0">
                        <RiLogoutCircleRFill color="white" size={16} style={{marginBottom:"7px"}} />  <span className="ms-1 d-none d-sm-inline"  style={{color:"#fff"}} >Logout</span>
                        </a>
                    </li>
                  
                  
              
                  
                </ul>
                </h6>
                <hr/>
                {/* <div className="dropdown pb-4">
                    <span href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                       
                        <span className="d-none d-sm-inline mx-1">Logout</span>
                    </span>
                  
                </div> */}
            </div>
        </div>

        <div className="col py-3">
        <div classNameName="container-fluid" >
                <div style={{ marginTop:"5px" }}>
                    
{/* <p>{ test } </p> */}
<center><h3> {select==1?"OptumPricer Input":select==2?<> OptumPricer Output  


  </>:"Loading..."}</h3> 
 </center>



 <div className="row">
    <div className="col-10" >

    </div>
    <div className="col-2">
    <h3 className="w-100" style={{float:"right"}}> {select==1?"":select==2?<>
<img  onClick={()=>{
                                        window.print();
                                    }}  className="d-print-none"  style={{marginTop:"2px"}} src="https://account.optumpricing.com/apis/0305.jpeg" width="40" height="40" />
<img  onClick={()=>{
                                        window.print();
                                    }} className="d-print-none"  style={{marginTop:"2px"}} src="https://account.optumpricing.com/apis/pdf.jpeg" width="40" height="35" />
  </>:""}</h3> 

    </div>

</div>







<span><b>Company Name:</b> <span style={{color:"red"}}>{login.length>0?login[0].email:""}</span>  <b style={{marginLeft:"40px"}} >Date:</b> <span style={{color:"black"}} >{new Date().toLocaleDateString("en-US", {
    
    "month": "numeric",
    "day": "numeric",
    "year": "numeric"
})}</span></span>

                   
                    {

                        select === 1 ?
                        
                                <div className="main_Cont" >
                                  
                                    <div className="section_one">
                                    <div className="row" style={{marginTop:"10px"}}  >
                                        <div className="col-6 col-sm-12 col-md-12">
                                            </div>
<div className="col-auto text-right">
<button type="button" style={{marginTop:"5px",width:"220px",height:"50px"}} onClick={()=>{
                                            setSelect(2)
                                            setSelect_view("input")
                                        }}  class="btn btn-primary"> Create Output <VscOutput color="white" size={16} style={{marginBottom:"7px"}} />  </button>
</div>

{/* <div className="col-auto text-right">
<button  type="button" style={{marginTop:"5px",width:"220px",height:"50px"}} onClick={()=>{

getSaved(login[0].id,login[0].cid)

}} class="btn btn-success">Save History <FaSave color="white" size={16} style={{marginBottom:"7px"}} /> </button>
</div>

<div className="col-auto text-right">
<button  type="button" style={{marginTop:"5px",backgroundColor:"#2596be",color:"white",width:"220px",height:"50px"}} class="btn "> Import File <CiImport color="white" size={16} style={{marginBottom:"7px"}} /></button>


    </div>

<div className="col-auto text-right">
<button type="button" style={{marginTop:"5px",backgroundColor:"#28666e",color:"white",width:"220px",height:"50px"}} class="btn s">Onboarding Template <AiFillFileExcel color="white" size={16} style={{marginBottom:"7px"}} /> </button>
    </div> */}

</div>

<h4 style={{marginTop:"30px"}}>P&L Data <span style={{color:"red",fontSize:"16px"}} ><b>{historyopen?"(History)":""}</b> </span> </h4>
          
           
           
        
                                        <div className="data">
                                            <div>
                                                <label className="input_label">total yr. revenue:</label>
<CurrencyInput
  id="input-example"
  name="input-name"
  disabled
  defaultValue={1000}
  value={ProductListInput[0].total_year_revenue}
  decimalsLimit={2}
  prefix="$"
  
/>
</div>



                                            <div>
                                                <label className="input_label">total COGS:</label>
                                                <CurrencyInput
  id="input-example"
  name="input-name"
disabled
  defaultValue={1000}
  value={ProductListInput[0].total_cogs}
  decimalsLimit={2}
  prefix="$"
 
/>
                                               
                                            </div>
                                            <div>
                                                <label className="input_label">Total direct labor cost:</label>
                                                <CurrencyInput
                                                
  id="input-example"
  name="input-name"
disabled
style={{cursor:"not-allowed",backgroundColor:"#f5f5f5"}}
  defaultValue={1000}
  value={ProductListInput[0].total_direct_labour}
  decimalsLimit={2}

  prefix="$"
 
/>


                                               
                                            </div>
                                            <div>
                                                <label className="input_label">total SG&A:</label>
                                                <CurrencyInput
  id="input-example"
  name="input-name"

  defaultValue={1000}
  value={ProductListInput[0].total_sga}
  decimalsLimit={2}
  prefix="$"
 
/>
                                               
                                            </div>
                                            <div>
                                                <label className="input_label">Total material/year:</label>
  <CurrencyInput
  id="input-example"
  name="input-name"
  style={{cursor:"not-allowed",backgroundColor:"#f5f5f5"}}
  defaultValue={1000}
  disabled
  value={ProductListInput[0].total_materia_per_year}
  decimalsLimit={2}
  prefix="$"
  
/> 
                                            </div>
                                            <div>
                                                <label className="input_label">CAPEX payments/yr:</label>
                                                <CurrencyInput
  id="input-example"
  name="input-name"

  defaultValue={1000}
  value={ProductListInput[0].capex_payment_per_year}
  decimalsLimit={2}
  prefix="$"
  
/>
                                                
                                            </div>
                                      
                                            <div>
                                                <label className="input_label"># of direct workers:</label>
                                                <Input value={ProductListInput[0].direct_workers } disabled style={{backgroundColor:"#f5f5f5",color:"#202020"}}  />
                                            </div>
                                            <div>
                                                <label className="input_label">Avg direct Labor $/Hr:</label>
                                                <CurrencyInput
  id="input-example"
  name="input-name"

  defaultValue={1000}
  value={ProductListInput[0].avg_direct_per_hr}
  decimalsLimit={2}
  prefix="$"
  
/>
                                                
                                            </div>


            


                                                <div>
                                                <label className="input_label">Comments:</label>
                                                <Input  value={ProductListInput[0].comments }  style={{color:"#202020"}}  />
                                            </div>

                                            {/* page 1 TOF  */}
                                                  {/* <div>
                                                <label className="input_label">TOF  :</label>
                                                <Input  value={ProductListInput[0].tof }  />
                                            </div> */}
                                        </div>

                                    </div>
                                    

                                    {/* Table start */}
                                    {/* <div className="section_two" >
                                        <div className="table_data">
                                            <div className="add_data" style={{ marginBottom: "12px"  }}>
                                            <span style={{ paddingTop: "7px",cursor:"pointer" }} onClick= {() => setActive(true)}>
                                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.5 6.75V9.75M9.5 9.75V12.75M9.5 9.75H12.5M9.5 9.75H6.5" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                                        <path d="M9.5 17.25C13.6421 17.25 17 13.8921 17 9.75C17 5.60786 13.6421 2.25 9.5 2.25C5.35786 2.25 2 5.60786 2 9.75C2 13.8921 5.35786 17.25 9.5 17.25Z" stroke="black" stroke-width="1.5" />
                                                    </svg>

                                                </span>
                                                <span>Add Product</span>
                                            </div>

                                            <div style={{ height: "auto" }} >
                                                <Table
                                                    style={{ fontSize: "1px", width: "100%", }}
                                                    pagination={false}

                                                    columns={columns} dataSource={data} />
                                            </div>

                                        </div>
                                    </div> */}

                                    {/* Table end */}
                                    <h4 style={{marginTop:"20px"}}>Product Line Data</h4>
                                    <div className="row alert alert-primary" style={{fontWeight:"bolder",height:"80px",marginBottom:"20px",marginTop:"20px"}}>
                                       
                        
                                    <div  className="col-1">
                                        <center><b>Products</b></center></div>
                                        <div  className="col-2">
                                        <center><b>Part Number</b></center>
                                            </div>
                                        <div  className="col-2">
                                       <center><b>Product Mat'l /Year</b></center>
                                            </div>
                                        <div  className="col-1">
                                       <center><b>Current Price/Bid</b></center>
                                            </div>
                                        <div  className="col-1">
                                        <center><b>Material Cost/Unit</b></center>
                                            </div>
                                        <div  className="col-1">
                                        <center><b>Direct Workers</b></center>
                                            </div>
                                        <div  className="col-2">
                                        <center><b>Production Units/Yr</b></center>
                                            </div>
                                        <div  className="col-1">
                                        <center><b>Direct Labor Unit/Min</b></center>
                                            </div>
                                            
                                            
                                       
                                        </div>
                                        


                                        {
                                            ProductListInput.map((v,i)=>(
                                               
                                               
                                             <>

                                             {/* Product start array  */}
                                             {
                                                i==0?<></>:<>
                                                  <div  className="row " style={{fontWeight:"bolder",fontSize:"14px",height:"50px",paddingTop:"10px",textAlign:"center"}}>
                                       
                                    
                                       <div className="col-1">
                                        
                                <Input 
                                 value={v.product}
                                 className="form-control"
                                 style={{ border:" 0.37484px solid #3755be",borderRadius: "4.49808px",height:"35px",fontSize:"14px"}}
                                    /></div>
                                   <div className="col-2"><Input class="form-control" value={ProductListInput[i].partnumber}
                                 style={{ border:" 0.37484px solid #3755be",borderRadius: "4.49808px",fontSize:"14px",height:"35px"}}   /></div>


                       

                                   <div className="col-2"><CurrencyInput
id="input-example"
name="input-name"

defaultValue={1}
value={v.materialCostYear}
decimalsLimit={2}
prefix="$"
className="form-control"
style={{ border:" 0.37484px solid #3755be",borderRadius: "4.49808px",height:"35px",fontSize:"14px"}}

/></div>

<div className="col-1">
<CurrencyInput
id="input-example"
name="input-name"

defaultValue={100}
value={v.currentPrise}
decimalsLimit={2}
prefix="$"
className="form-control"
style={{ border:" 0.37484px solid #3755be",borderRadius: "4.49808px",height:"35px",fontSize:"14px"}}

/> 
                                  
                                       </div>
                                   <div className="col-1">
                                   <CurrencyInput
id="input-example"
name="input-name"

defaultValue={100}
disabled
value={v.materialCostUnit}
decimalsLimit={2}

prefix="$"
className="form-control"
style={{ border:" 0.37484px solid #3755be",borderRadius: "4.49808px",height:"35px",fontSize:"14px",cursor:"not-allowed",backgroundColor:"#f5f5f5"}}

/> 
                                 
                                       </div>
                                   <div className="col-1">
                                   <Input  value={v.directWork}
                                    className="form-control w-100"

                                    style={{ border:" 0.37484px solid #3755be",borderRadius: "4.49808px",height:"35px",fontSize:"14px"}}   />
                                       </div>
                                   <div className="col-2">
                                   <CurrencyInput
id="input-example"
name="input-name"

defaultValue={100}

value={v.ProductUnitsYear}
decimalsLimit={2}
className="form-control"
style={{ border:" 0.37484px solid #3755be",borderRadius: "4.49808px",height:"35px",fontSize:"14px"}}

/> 
                                   
                                       </div>
                                   <div className="col-1">
                                   <Input  value={v.directLabour}  className="form-control"
style={{ border:" 0.37484px solid #3755be",borderRadius: "4.49808px",height:"35px",fontSize:"14px",color:"black"}} disabled    />
                                       </div>


                                       
                                  
                                  
                                   </div>
                                   </>
                                             }
                                             
                                             </>
                                            ))
                                        }
                                             <div>
                                          
                                                </div>

                                    
                                </div>

                                :

                                select==2?<>



                                {/* Page 2  page2*/}
                           <div className="container-fluid">
                           <div className="main_Cont">
                                

                                <div className="output_mainCont">
                                
                                <div className="data">
                                <div>
                                    <label className="input_label">Product</label>
                                    <select value={selectProductIndex} onChange={(v)=>{
                                        setSelectProductIndex(v.target.value)
                                        console.log(v.target.value)
                                    }} className="select" style={{backgroundColor:"#cfe2ff",width:"200px",}}>
                                        {ProductListInput.map((v,i)=>(
                                
                                            <>
                                            {
                                                i==0?<></>:<option value={i}>{v.product}</option>
                                            }
                                            </>
                                            
                                        ))}
                                    </select>
                                
                                </div>
                                <div>
                                    <label className="input_label">Part no:</label>
                                    <Input className="Input" disabled style={{cursor:"not-allowed",backgroundColor:"#f5f5f5",color:"#202020",width:"200px"}}  value={ProductListInput[selectProductIndex].partnumber}  />
                                </div>
                                
                                <div>
                                    <label className="input_label">Product $ Mat Cost/Yr:</label>
                                    <CurrencyInput
                                id="input-example"
                                name="input-name"
                                
                                defaultValue={100}
                                
                                value={ProductListInput[selectProductIndex].materialCostYear}
                                decimalsLimit={2}
                            
                                disabled
                                prefix="$"
                                style={{ height: "38px",
                                      padding: "10px",
                                      border:" 0.37484px solid #3755be",
                                      borderRadius: "4.49808px",
                                      outline: "none",
                                      fontweight: "400",
                                      fontSize:" 13.4943px",
                                      lineHeight: "18px",
                                      cursor:"not-allowed",
                                      backgroundColor:"#f5f5f5",width:"200px" }} 
                                
                                /> 
                                   
                                </div>
                                <div>
                                    <label className="input_label">Current Price/Bid:</label>
                                    <CurrencyInput
                                id="input-example"
                                name="input-name"
                                
                                defaultValue={100}
                                
                                value={ProductListInput[selectProductIndex].currentPrise}
                                decimalsLimit={2}
                         
                                disabled
                                prefix="$"
                                style={{ height: "38px",
                                      padding: "10px",
                                      border:" 0.37484px solid #3755be",
                                      borderRadius: "4.49808px",
                                      outline: "none",
                                      fontweight: "400",
                                      fontSize:" 13.4943px",
                                      lineHeight: "18px",cursor:"not-allowed",backgroundColor:"#f5f5f5",width:"200px" }} 
                                
                                />
                                   
                                </div>
                                
                                <div>
                                    <label className="input_label"># Of Direct Workers:</label>
                                    <Input className="Input" disabled style={{cursor:"not-allowed",backgroundColor:"#f5f5f5",color:"#202020",width:"200px"}}  value={ProductListInput[selectProductIndex].directWork}  />
                                </div>
                                
                                
                                <div>
                                    <label className="input_label">Product Units/Yr:</label>
                                    <CurrencyInput
                                
                                
                                defaultValue={100}
                                
                                value={ProductListInput[selectProductIndex].ProductUnitsYear}
                                decimalsLimit={2}
                              
                                disabled
                                
                                style={{ height: "38px",
                                      padding: "10px",
                                      border:" 0.37484px solid #3755be",
                                      borderRadius: "4.49808px",
                                      outline: "none",
                                      fontweight: "400",
                                      fontSize:" 13.4943px",width:"200px",
                                      lineHeight: "18px",cursor:"not-allowed",backgroundColor:"#f5f5f5",width:"200px" }} 
                                
                                />
                                
                                   
                                </div>
                                
                                {/* extra */}
                                
                                
                                <div>
                                    <label className="input_label" style={{color:"#0000ff"}} >
                                         {/* output  */}
                                         Material Cost/Unit:</label>
                                    <CurrencyInput
                                id="input-example"
                                name="input-name"
                                
                                defaultValue={100}
                                
                                value={ProductListInput[selectProductIndex].materialCostUnit}
                                decimalsLimit={2}
                              
                                
                                prefix="$"
                                style={{ height: "38px",
                                      padding: "10px",
                                      border:" 0.37484px solid #3755be",
                                      borderRadius: "4.49808px",
                                      outline: "none",
                                      fontweight: "400",
                                      fontSize:" 13.4943px",
                                      lineHeight: "18px",width:"200px", }} 

                                />
                                   
                                </div>
                                <div>
                                    <label className="input_label" style={{color:"#0000ff"}}>Direct Labor/Unit - Min:</label>
                                
                                    <Input  className="Input" style={{width:"200px"}}   value={ProductListInput[selectProductIndex].directLabour}  />
                                </div>
                                <div>
                                    <label className="input_label" style={{color:"#0000ff"}}>Current Avg. Direct Labor $/Hr:</label>
                                    <CurrencyInput
                                  id="input-example"
                                  name="input-name"
                                
                                  defaultValue={1000}
                                  value={ProductListInput[selectProductIndex].current_avg_direct_labor_per_hr}
                                  decimalsLimit={2}
                                
                                
                                 
                                
                                prefix="$"
                                style={{ height: "38px",
                                      padding: "10px",
                                      border:" 0.37484px solid #3755be",
                                      borderRadius: "4.49808px",
                                      outline: "none",
                                      fontweight: "400"
                                      ,width:"200px",
                                      fontSize:" 13.4943px",
                                      lineHeight: "18px" }} 
                                />
                                
                                    
                                   
                                </div>

                             
                                
                                
                                {/* output Tof */}
                                {/* <div>
                                    <label className="input_label">TOF :</label>
                                    <CurrencyInput
                                id="input-example"
                                name="input-name"
                                
                                defaultValue={100}
                                
                                value={ProductListInput[0].tof}
                                decimalsLimit={2}
                                className="w-100"
                                disabled
                                prefix="$"
                                style={{ height: "38px",
                                      padding: "10px",
                                      border:" 0.37484px solid #3755be",
                                      borderRadius: "4.49808px",
                                      outline: "none",
                                      fontweight: "400",
                                      fontSize:" 13.4943px",
                                      lineHeight: "18px" }}
                                
                                />
                                    
                                </div> */}
                                
                                
                                {/* output current Tof */}
                                
                                {/* <div>
                                    <label className="input_label">Current TOF :</label>
                                    <Input type="number" className="Input" disabled style={{backgroundColor:"#f5f5f5",color:"#202020",}}   value={ProductListInput[selectProductIndex].current_tof}  />
                                </div> */}
                                </div>
                            
                                <div style={{marginTop:"20px"}}  className="container-fluid">
                                <div className="row">
                                    <div className="col-11">
                                    <div className="card">
                                <table  class="table table-hover">
                                
                                
                                <thead className="alert alert-primary" >
                                  <tr>
                                    <th>Profit %</th>
                                    <th>Unit Price</th>
                                    <th>Net Profit/Unit</th>
                                    <th>Gross Margin</th>
                                    <th>Product Line Profitability</th>
                                    
                                  </tr>
                                </thead>
                                    
                                   
                                <tbody>
                                  <tr>
                                    <td>Current Price</td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].unit_price_1) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].net_profitPer_unit_1) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].grossMargin_1) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].product_Line_1) } </td>
                                   
                                   
                                  </tr>
                                  <tr>
                                    <td>Break Even</td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].unit_price_2 )} </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].net_profitPer_unit_2) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].grossMargin_2) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].product_Line_2) } </td>
                                  </tr>
                                  <tr>
                                    <td>5.0%</td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].unit_price_3) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].net_profitPer_unit_3) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].grossMargin_3) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].product_Line_3) } </td>
                                  </tr>
                                  <tr>
                                    <td>7.5%</td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].unit_price_4) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].net_profitPer_unit_4) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].grossMargin_4) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].product_Line_4) } </td>
                                  </tr>
                                  <tr>
                                    <td>10.0%</td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].unit_price_5) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].net_profitPer_unit_5) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].grossMargin_5) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].product_Line_5) } </td>
                                  </tr>
                                  <tr>
                                    <td>15.0%</td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].unit_price_6) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].net_profitPer_unit_6) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].grossMargin_6) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].product_Line_6) } </td>
                                  </tr>
                                  <tr>
                                    <td>20.0%</td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].unit_price_7) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].net_profitPer_unit_7) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].grossMargin_7) } </td>
                                    <td>{ formatter.format(ProductListInput[selectProductIndex].product_Line_7) } </td>
                                  </tr>
                                  
                                
                                
                                </tbody>
                                
                                </table>
                                </div>
                                
                                    </div>
                                
                                </div>
                                </div>
                                
                                
                                {/* <div style={{ width: "100%", marginTop: "45px" }}>
                                
                                <Table
                                    style={{ fontSize: "1px", width: "100%", boxShadow: "2.99167px 2.99167px 18.6979px rgba(0, 0, 0, 0.1)" }}
                                    pagination={false}
                                
                                    columns={columnss} dataSource={dataa} />
                                
                                
                                </div> */}
                                
                                
                                
    
                               
                                
                                
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "10px", }}>
                                    <p style={{
                                        fontSize: "18px",
                                        lineHeight: "25px",
                                        fontWeight: "500",
                                        textTransform: "capitalize",
                                        color: " #3755BE",
                                
                                    }}>Net Profit vs. Gross Margin vs. Unit Price</p>
                                </div>
                                
                                
                                
                                
                                <div style={{ width: "90%", height: "35%", display: "flex", justifyContent: 'center', marginTop: "1px"  }}>
                                    <div style={{
                                        fontWeight: "500", fontSize: "17px",
                                        color: " #3755BE", height: "100%",
                                        display: "flex", alignItems: "center", justifyContent: "flex-end"
                                
                                    }}>
                                        {/* Axis 1 axis1 */}
                                        <span style={{ transform: "rotate(270deg)",fontWeight: "500", fontSize: "17px", color: " #3755BE",  }}>Profit</span>
                                    </div>
                                


                                    <div style={{ width: "100%", height: "100%",  }}>
                                
                                
                                
                                        <ResponsiveContainer  >
                                            <LineChart
                                           style={{backgroundColor:"#f7f8fd "}}
                                                data={ProductListInput[selectProductIndex].array}
                                
                                
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" padding={{ left: 20, right: 50,top:40 }} tick={CustomizedXAxisTick} />
                                                <YAxis padding={{ left: 20, right: 30,top:40, bottom: 10 }}  tick={CustomizedYAxisTick} />
                                
                                                <Line dataKey="profitA" stroke="#1DEF26"  strokeWidth={2} dot={false}> {/* for dot on grapg Line add dot={true} */}
                                                    <LabelList   content={<CustomizedLabel />} />
                                                </Line>
                                
                                                {/* <Line dataKey="c" stroke="#202020" strokeWidth={2} dot={false}> 
                                                    <LabelList content={<CustomizedLabel />} />
                                                </Line> */}
                                
                                
                                                <Line dataKey="profitB" stroke="#FF994F"  strokeWidth={2} dot={false} > {/* for dot on grapg Line add dot={true} */}
                                                    <LabelList content={<CustomizedLabel />} />
                                                </Line>
                                         
                                
                                            </LineChart>
                                
                                        </ResponsiveContainer>
                                
                                {/* Data Labels  start  */}
                                        <div style={{
                                            width: "100%", display: "flex",
                                            justifyContent: "center",
                                            fontWeight: "500", fontSize: "17px",  color: " #3755BE",
                                        }}>Unit Price </div>
                                
                                
                                
                                {/* legend 1   */}
                                
                                
                                        <div style={{ display: "flex", width: "90%", gap: "25px", justifyContent: "flex-end" }}>
                                            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                                <div style={{ width: "49px", height: "9px", backgroundColor: "#1DEF26", borderRadius: "5px" }}> </div>
                                                <span style={{ fontWeight: "500", fontSize: "17px" }}>Net Profit</span>
                                
                                            </div>
                                {/* legend 2 */}
                                            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                
                                                <div style={{ width: "49px", height: "9px", backgroundColor: " #FF994F", borderRadius: "5px" }}> </div>
                                                <span style={{ fontWeight: "500", fontSize: "17px" }}>Gross Margin</span>
                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                
                                </div>
                                
                                
                                
                                
                                </div>
                                                            </div>
                       
                            
                            
                            
                            
                            
                            </>:<div style={{marginTop:"200px"}}> 
{/* {Loder} */}
<center><Hypnosis color="#ADD8E6" onClick={() => alert("Clicked")} key="key" width="100px" height="100px"  duration="3s" />Loading...</center> </div>
                        
                        
                    }
                    
                </div>



            </div>
        </div>
    </div>
</div>

           

            
             
                    
                    

             
                
           
        </div>
    )

}
export default Admin;