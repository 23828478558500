import React,{useState} from "react";
import SideBar from "../SideBar/SideBar";
import "./Admin_Panel.scss"
import Admin from "../Pages/Pages_one/Admin"
import Output from "../Pages/pages_two/Output";
import 'bootstrap/dist/css/bootstrap.min.css'; 




const Admin_Panel = () => {
  
    return (


<div >
        
            
           
            
            <Admin  /> 



  
</div>

      

    )
}
export default Admin_Panel;